<template>
	<el-dialog
		title=""
		:visible.sync="createdVisible"
		width="578px"
		center
		:close-on-click-modal="false"
		@close="onResetForm('ruleForm')"
		:show-close="false"
	>
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}社区</span>
		</div>
		<el-form :model="ruleForm" :rules="isShowDetail ? {} : rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="当前帐号所属区域" class="disabledClass clearfix">
				<el-input class="float-l" v-model.trim="economize" disabled placeholder="请输入"></el-input>
				<el-input class="float-l" v-model.trim="market" disabled placeholder="请输入"></el-input>
				<el-input class="float-l" v-model.trim="district" disabled placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="街道/乡镇" prop="townCode">
				<el-select :disabled="isShowDetail" v-model="ruleForm.townCode" placeholder="请选择" clearable>
					<el-option v-for="(item, index) in townList" :key="index" :label="item.title" :value="item.code"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="社区编号" prop="code" v-if="ruleForm.townCode">
				<el-input
					v-model.trim="ruleForm.code"
					:disabled="isShowDetail"
					placeholder="请输入"
					maxlength="3"
					oninput="value=value.replace(/^[^0-9]/g, '')"
				></el-input>
			</el-form-item>
			<el-form-item label="社区名称" prop="title">
				<el-input v-model.trim="ruleForm.title" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
			<el-button v-else type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { verifiyNumberInteger } from '@/utils/toolsValidate';
export default {
	data() {
		var checkNumber = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入3位社区编号'));
			} else if (!verifiyNumberInteger(value)) {
				callback(new Error('请输入正确的社区编号'));
			} else {
				callback();
			}
		};
		return {
			createdVisible: false,
			saveLoading: false,
			economize: '',
			market: '',
			district: '',
			ruleForm: {
				code: '',
				title: '',
				townCode: '',
			},
			townList: [], //乡镇/街道list
			rules: {
				code: [{ required: true, validator: checkNumber, trigger: 'blur' }],
				title: [{ required: true, message: '请输入社区名称', trigger: 'blur' }],
				townCode: [{ required: true, message: '请选择所属乡镇/街道', trigger: 'change' }],
			},
		};
	},
	props: ['isShowDetail'],
	methods: {
		init(info) {
			this.createdVisible = true;
			this.link = info.link;
			let region = JSON.parse(sessionStorage.getItem('userInfo'))?.chinaRegionDtoList || [];
			this.economize = region && region.length > 0 && region[0].title;
			this.market = region && region.length > 0 && region[1].title;
			this.district = region && region.length > 0 && region[2].title;
			this.getTown(region[2].code);
			if (info.row) {
				this.$nextTick(() => {
					this.ruleForm = JSON.parse(JSON.stringify(info.row));
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
			//新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		// 获取乡镇/街道list
		getTown(code) {
			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { code } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.townList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		onResetForm(formName) {
			this.createdVisible = false;
			this.$refs[formName].resetFields();
		},
		//保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					let params = {
						...this.ruleForm,
						code: this.ruleForm.townCode + this.ruleForm.code,
					};
					let promiseUrl = this.ruleForm.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
					promiseUrl
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.onResetForm('ruleForm');
								this.saveLoading = false;
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				}else {
					this.$nextTick(() => {
						let isError = document.getElementsByClassName('is-error');
						// var a = $(isError[0]).find("input") //this.$refs.cmd.$el.querySelector('input')
						// a.focus()  光标定位到所在input
						isError[0].scrollIntoView({
							// 滚动到指定节点
							// 值有start,center,end，nearest
							block: 'center',
							// 值有auto、instant,smooth，缓动动画
							behavior: 'smooth',
						});
					});
					return false;
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item .is-disabled {
		width: calc((100% - 32px) / 3) !important;
		margin-right: 16px;
		&:last-child {
			margin-right: 0;
		}
	}
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}

	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
}
</style>